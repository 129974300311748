/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import * as THREE from 'three';
import TileMesh from "../Core/TileMesh";
import LayeredMaterial from "../Renderer/LayeredMaterial";
import newTileGeometry from "../Core/Prefab/TileBuilder";
import ReferLayerProperties from "../Layer/ReferencingLayerProperties";
import { geoidLayerIsVisible } from "../Layer/GeoidLayer";
var dimensions = new THREE.Vector2();

function setTileFromTiledLayer(tile, tileLayer) {
  if (tileLayer.diffuse) {
    tile.material.diffuse = tileLayer.diffuse;
  }

  if (tileLayer.isGlobeLayer) {
    // Computes a point used for horizon culling.
    // If the point is below the horizon,
    // the tile is guaranteed to be below the horizon as well.
    tile.horizonCullingPoint = tile.extent.center().as('EPSG:4978').toVector3();
    tile.extent.planarDimensions(dimensions).multiplyScalar(THREE.MathUtils.DEG2RAD); // alpha is maximum angle between two points of tile

    var alpha = dimensions.length();
    var h = Math.abs(1.0 / Math.cos(alpha * 0.5));
    tile.horizonCullingPoint.setLength(h * tile.horizonCullingPoint.length());
    tile.horizonCullingPointElevationScaled = tile.horizonCullingPoint.clone();
  }
}

export default {
  convert: function convert(requester, extent, layer) {
    var builder = layer.builder;
    var parent = requester;
    var level = parent !== undefined ? parent.level + 1 : 0;
    var paramsGeometry = {
      extent: extent,
      level: level,
      segment: layer.segments || 16,
      disableSkirt: layer.disableSkirt
    };
    return newTileGeometry(builder, paramsGeometry).then(function (result) {
      // build tile mesh
      result.geometry._count++;
      var crsCount = layer.tileMatrixSets.length;
      var material = new LayeredMaterial(layer.materialOptions, crsCount);
      ReferLayerProperties(material, layer);
      var tile = new TileMesh(result.geometry, material, layer, extent, level); // Commented because layer.threejsLayer is undefined;
      // Fix me: conflict with object3d added in view.scene;
      // tile.layers.set(layer.threejsLayer);

      if (parent && parent.isTileMesh) {
        // get parent extent transformation
        var pTrans = builder.computeSharableExtent(parent.extent); // place relative to his parent

        result.position.sub(pTrans.position).applyQuaternion(pTrans.quaternion.invert());
        result.quaternion.premultiply(pTrans.quaternion);
      }

      tile.position.copy(result.position);
      tile.quaternion.copy(result.quaternion);
      tile.visible = false;
      tile.updateMatrix();
      tile.add(tile.obb);
      setTileFromTiledLayer(tile, layer);

      if (parent) {
        tile.geoidHeight = parent.geoidHeight;
        var geoidHeight = geoidLayerIsVisible(layer) ? tile.geoidHeight : 0;
        tile.setBBoxZ({
          min: parent.obb.z.min,
          max: parent.obb.z.max,
          geoidHeight: geoidHeight
        });
        tile.material.geoidHeight = geoidHeight;
      }

      return tile;
    });
  }
};