function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import * as THREE from 'three';
import GeoidGrid from "../Core/Geographic/GeoidGrid";
import Extent from "../Core/Geographic/Extent";
import { getHeaderAttribute } from "./GDFParser";
import { BYTES_PER_DOUBLE } from "./GTXParser";
/**
 * The `ISGParser` module provides a `[parse]{@link module:ISGParser.parse}` method. This method takes the content of a
 * ISG file in, and returns a `{@link GeoidGrid}`. the `{@link GeoidGrid}` contains all the necessary attributes and
 * methods to access the ISG data in iTowns.
 *
 * @module ISGParser
 */

export default {
  /**
   * Parses an ISG file content and returns a corresponding `{@link GeoidGrid}`.
   *
   * @param   {string}    isg                             The content of the ISG file to parse.
   * @param   {Object}    options                         An object gathering the optional parameters to pass to
                                                          * the parser.
   * @param   {Object}    [options.in={}]                 Information on the ISG data.
   * @param   {string}    [options.in.crs='EPSG:4326']    The Coordinates Reference System (CRS) of the ISG data.
                                                          * It must be a geographic CRS, and must be given as an EPSG
                                                          * code.
   *
   * @returns {Promise<GeoidGrid>}    A promise resolving with a `{@link GeoidGrid}`, which contains all the necessary
                                      * attributes and methods to access ISG file data.
   */
  parse: function parse(isg) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      "in": {}
    };
    var rows = isg.split('\n');
    var firstMeasureLine = rows.indexOf(rows.find(function (row) {
      return row.includes('end_of_head');
    })) + 1;
    var rawHeaderData = rows.slice(0, firstMeasureLine); // ---------- GET METADATA FROM THE FILE : ----------

    var metadata = {
      minX: getHeaderAttribute(rawHeaderData, 'lon min'),
      maxX: getHeaderAttribute(rawHeaderData, 'lon max'),
      minY: getHeaderAttribute(rawHeaderData, 'lat min'),
      maxY: getHeaderAttribute(rawHeaderData, 'lat max'),
      stepX: getHeaderAttribute(rawHeaderData, 'delta lon'),
      stepY: getHeaderAttribute(rawHeaderData, 'delta lat'),
      nRows: getHeaderAttribute(rawHeaderData, 'nrows'),
      nColumns: getHeaderAttribute(rawHeaderData, 'ncols')
    }; // ---------- BUILD A DATA VIEWER FROM THE TEXT DATA : ----------

    var data = new DataView(new ArrayBuffer(BYTES_PER_DOUBLE * metadata.nRows * metadata.nColumns));
    var index = 0;

    var _iterator = _createForOfIteratorHelper(rows.slice(firstMeasureLine, rows.length)),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var row = _step.value;
        row = row.split(' ').filter(function (value) {
          return value !== '';
        });

        if (!row.length) {
          continue;
        }

        var _iterator2 = _createForOfIteratorHelper(row),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var value = _step2.value;
            data.setFloat64(index * BYTES_PER_DOUBLE, parseFloat(value));
            index++;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      } // ---------- CREATE A GeoidGrid FOR THE GIVEN FILE DATA : ----------

    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    var dataExtent = new Extent(options["in"].crs || 'EPSG:4326', metadata.minX + metadata.stepX / 2, metadata.maxX - metadata.stepX / 2, metadata.minY + metadata.stepY / 2, metadata.maxY - metadata.stepY / 2);
    var dataStep = new THREE.Vector2(metadata.stepX, metadata.stepY);
    return Promise.resolve(new GeoidGrid(dataExtent, dataStep, function getData(verticalIndex, horizontalIndex) {
      return data.getFloat64((metadata.nColumns * verticalIndex + horizontalIndex) * BYTES_PER_DOUBLE);
    }));
  }
};